import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { useQuery } from '@tanstack/react-query';
import { Col, Row } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { Tweet } from 'react-twitter-widgets';
import styled from 'styled-components';

import Aileen from '../assets/images/aileen.png';
import Gretchen from '../assets/images/gretchen.png';
import SchoolLogo7 from '../assets/images/LandingPage/CISDlogo.png';
import SchoolLogo4 from '../assets/images/LandingPage/DALTONlogo.png';
import SchoolLogo6 from '../assets/images/LandingPage/DELAWARElogo.png';
import DrawingImage from '../assets/images/LandingPage/drawing.webp';
import SchoolLogo1 from '../assets/images/LandingPage/IPlogo.png';
import SchoolLogo5 from '../assets/images/LandingPage/MIlogo.png';
import SchoolLogo2 from '../assets/images/LandingPage/PATRIOTlogo.png';
import PersonalisedFeedbackImage from '../assets/images/LandingPage/personalisedFeedback.webp';
import PodiumImage from '../assets/images/LandingPage/podium.webp';
import PollImage from '../assets/images/LandingPage/poll.webp';
import SchoolLogo3 from '../assets/images/LandingPage/SANBENITOlogo.png';
import WordcloudImage from '../assets/images/LandingPage/wordcloud.webp';
import Stephanie from '../assets/images/stephanie.png';
import theme from '../assets/theme';
import { Button } from '../components/_atoms/Button';
import { CollectionSlidingView } from '../components/_organisms/CollectionView/CollectionView';
import { useMetrics } from '../hooks/useMetrics';
import { GeneratorMinimumDTO } from '../services/backendService/types';

const UnauthenticatedHome = () => {
  return (
    <Container>
      <ExcitementForLearning />
      <ManyStudents />
      <ThousandsOfClassrooms />
      <StandardsAlignedLessons />
      <Collections />
      <SignupForLiteracyLessons />
      <TeacherStory />
      <SchoolAndDistrictPlanPitch />
      <TwitterGrid />
      <SeeWhatYouCanAccomplish />
    </Container>
  );
};

const SeeWhatYouCanAccomplish = () => {
  const router = useRouter();
  const metrics = useMetrics();

  const handleSignupClicked = () => {
    metrics.logEvent('FrontPage.SignupClicked', {
      from: 'tryForFree',
    });
    router.push({
      pathname: '/signup',
      query: { callback: window.location.href },
    });
  };
  return (
    <div style={{ width: '100%', padding: '100px 60px' }}>
      <ResponsiveH2 style={{ width: '100%', textAlign: 'center', marginBottom: 40 }}>
        Spark curiosity and discussion in your classroom with Curipod
      </ResponsiveH2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          <Button
            styles={{ backgroundColor: theme.colors.orange, width: '150px' }}
            onClick={() => handleSignupClicked()}
          >
            Try for free
          </Button>
        </div>
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          <a href="/quotes" target="_curipod_quote">
            <Button
              type="ghost"
              styles={{
                backgroundColor: 'transparent',
                borderColor: theme.colors.black,
                color: theme.colors.black,
                width: '150px',
              }}
            >
              Get a school quote
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

const SchoolAndDistrictPlanPitch = () => {
  const metrics = useMetrics();
  return (
    <div style={{ width: '100%', padding: '80px 20px', background: theme.colors.green }}>
      <ResponsiveH2
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          maxWidth: 1128,
          margin: 'auto',
          color: 'white',
          marginBottom: 20,
        }}
      >
        Go further with the most innovative and engaging ELA/RLA state test prep solution
        in all 50 states
      </ResponsiveH2>
      <ResponsiveP
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          maxWidth: 1128,
          margin: 'auto',
          color: 'white',
          marginBottom: 40,
          fontStyle: 'italic',
        }}
      >
        Available on the school and district plan
      </ResponsiveP>
      <KeyFeaturesResponsiveContainer>
        <div
          style={{
            height: 170,
            width: '100%',
            background: 'white',
            borderRadius: 10,
            boxShadow: theme.effects.floatingCreatorElementShadow,
            padding: 20,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: theme.colors.black,
              marginBottom: 8,
            }}
          >
            Standards aligned
          </p>
          <span style={{ fontWeight: 'normal' }}>
            Our test prep lessons are aligned with the standards, the questions and
            rubrics used in every state test
          </span>
        </div>
        <div
          style={{
            height: 170,
            width: '100%',
            background: 'white',
            borderRadius: 10,
            boxShadow: theme.effects.floatingCreatorElementShadow,
            padding: 20,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: theme.colors.black,
              marginBottom: 8,
            }}
          >
            Endless Lessons
          </p>
          <span style={{ fontWeight: 'normal' }}>
            1000s of full lessons with reading passages and questions on topics your
            students care about.
          </span>
        </div>
        <div
          style={{
            height: 170,
            width: '100%',
            background: 'white',
            borderRadius: 10,
            boxShadow: theme.effects.floatingCreatorElementShadow,
            padding: 20,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: theme.colors.black,
              marginBottom: 8,
            }}
          >
            Track Improvement
          </p>
          <span style={{ fontWeight: 'normal' }}>
            Generate classroom and individual reports with one click so you can follow up
            progress this test season.
          </span>
        </div>
        <div
          style={{
            height: 170,
            width: '100%',
            background: 'white',
            borderRadius: 10,
            boxShadow: theme.effects.floatingCreatorElementShadow,
            padding: 20,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: theme.colors.black,
              marginBottom: 8,
            }}
          >
            Simple & flexible implementation
          </p>
          <span style={{ fontWeight: 'normal' }}>
            Curipod seamlessly integrates into your current curriculum.
          </span>
        </div>
        <div
          style={{
            height: 170,
            width: '100%',
            background: 'white',
            borderRadius: 10,
            boxShadow: theme.effects.floatingCreatorElementShadow,
            padding: 20,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: theme.colors.black,
              marginBottom: 8,
            }}
          >
            FERPA & COPPA compliant
          </p>
          <span style={{ fontWeight: 'normal' }}>
            Student privacy is a top priority. Curipod is compliant with FERPA, COPPA and
            other state laws
          </span>
        </div>
      </KeyFeaturesResponsiveContainer>
      <div style={{ display: 'grid', justifyContent: 'center', marginTop: 40 }}>
        <Link href={'/pricing'} passHref>
          <a>
            <Button
              type="primary"
              styles={{
                backgroundColor: theme.colors.orange,
                color: 'white',
                borderColor: theme.colors.orange,
              }}
              onClick={() => {
                metrics.logEvent('FrontPage.PricingClicked', {
                  from: 'pricingPitch',
                });
              }}
            >
              See pricing
            </Button>
          </a>
        </Link>
      </div>
    </div>
  );
};

const KeyFeaturesResponsiveContainer = styled.div`
  padding: 20px 0px;
  display: grid;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Distribute items evenly */

  gap: 20px;
  width: 100%;
  max-width: 1128px;
  margin: auto;

  > * {
    flex: 0 1 320px; /* Replace 200px with your desired minimum width */
  }
`;

const StandardsAlignedLessons = () => {
  return (
    <div style={{ width: '100%', padding: '80px 20px', background: theme.colors.green }}>
      <ResponsiveH2
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          maxWidth: 1128,
          margin: 'auto',
          color: 'white',
        }}
      >
        CCSS and TEKS aligned interactive lessons you can personalize for your students
        with one click!
      </ResponsiveH2>
    </div>
  );
};

const ResponsiveH2 = styled.h2`
  font-size: 40px;

  @media (max-width: 990px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

const ResponsiveP = styled.p`
  font-size: 30px;

  @media (max-width: 990px) {
    font-size: 26px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const TwitterGrid = () => {
  return (
    <WidthContainer>
      <Row
        gutter={[10, 10]}
        style={{ marginTop: 0, marginBottom: 80, width: '100%', padding: '40px 0px' }}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1788165893140209749" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1786022134147399859" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1785288229622550586" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1686180615077433346" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1689768899376537601" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1686845883806056448" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1689992962279710720" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1782220435003670867" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tweet tweetId="1689756295920746496" />
        </Col>
      </Row>
    </WidthContainer>
  );
};

const WidthContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  position: relative;
  @media ${theme.device.laptop} {
    width: 600px;
  }
  @media ${theme.device.tablet} {
    width: 400px;
  }
  @media ${theme.device.mobileL} {
    width: 300px;
  }
`;

const Container = styled.div`
  min-height: 100vh;
`;

const SectionTitle = styled.h2`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Collections = () => {
  const { data: collections } = useQuery(
    ['front-page-collections'],
    async () => {
      // This is prefetched on the server, so I'm just mocking the promise resolve for typescript.
      // stale time will prevent this query to be fetched.
      return Promise.resolve<
        {
          title: string;
          collectionId: string;
          generators: GeneratorMinimumDTO[];
        }[]
      >([]);
    },
    {
      staleTime: Infinity,
    },
  );

  return (
    <div
      style={{
        background: theme.colors.green,
      }}
    >
      <div
        style={{
          padding: '80px 20px',
          width: '100%',
          maxWidth: '1280px',
          margin: 'auto',
        }}
      >
        {collections &&
          collections.map(collection => (
            <CollectionSlidingView
              key={collection.collectionId}
              collectionId={collection.collectionId}
              generators={collection.generators}
              title={collection.title}
            />
          ))}
      </div>
    </div>
  );
};

const ExcitementForLearning = () => {
  const { t } = useTranslation('Host');
  const router = useRouter();
  const metrics = useMetrics();

  const handleSignupClicked = () => {
    metrics.logEvent('FrontPage.SignupClicked', {
      from: 'top',
    });
    router.push({
      pathname: '/signup',
      query: { callback: window.location.href },
    });
  };
  return (
    <ActivitiesContainer>
      <CarouselContainer>
        <CarouselWrapper>
          <CarouselQuotes>
            <ActivityTitle>
              {t('excitementTogether', 'Excitement for learning, together! ')}
            </ActivityTitle>
            <ResponsiveCTA>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  gap: 10,
                }}
              >
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Teachers
                </span>
                <Button
                  styles={{ backgroundColor: theme.colors.orange, width: '150px' }}
                  onClick={() => handleSignupClicked()}
                >
                  Sign up
                </Button>
              </div>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  gap: 10,
                }}
              >
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Administrators
                </span>
                <a href="/quotes/" target="_curipod_quote">
                  <Button
                    type="ghost"
                    styles={{
                      backgroundColor: 'transparent',
                      borderColor: 'white',
                      color: 'white',
                      width: '150px',
                    }}
                    onClick={() => {
                      metrics.logEvent('FrontPage.QuoteClicked', {
                        from: 'top',
                      });
                    }}
                  >
                    Get a school quote
                  </Button>
                </a>
              </div>
            </ResponsiveCTA>
          </CarouselQuotes>
          <Carousel
            emulateTouch={false}
            interval={5000}
            autoPlay={true}
            showArrows={false}
            showIndicators={false}
            infiniteLoop={true}
            showThumbs={true}
            showStatus={false}
            swipeable={true}
          >
            <div>
              <img
                src={PersonalisedFeedbackImage.src}
                alt={t('personalised feedback result')}
              />
            </div>
            <div>
              <img src={PodiumImage.src} alt={t('podium result')} />
            </div>
            <div>
              <img src={WordcloudImage.src} alt={t('wordcloud result')} />
            </div>
            <div>
              <img src={DrawingImage.src} alt={t('drawing result')} />
            </div>
            <div>
              <img src={PollImage.src} alt={t('poll result')} />
            </div>
          </Carousel>
        </CarouselWrapper>
      </CarouselContainer>
    </ActivitiesContainer>
  );
};

const ResponsiveCTA = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const ActivitiesContainer = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  background-color: ${theme.colors.green};
  overflow: hidden;

  &:-webkit-scrollbar {
    display: none;
  }
  * {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  *::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
const ActivityTitle = styled.h1`
  width: 100%;
  text-align: left;
  color: white;
  padding-left: 10%;
  margin-bottom: 40px;
  z-index: 1;
  font-size: 34px;

  @media (max-width: 900px) {
    margin-bottom: 20px;
    padding-left: 0;
    text-align: center;
  }
`;
const CarouselQuotes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-left: 20px;
  padding-right: 50px;
  padding-bottom: 40px;
  width: 100%;
  @media (max-width: 900px) {
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  h1 {
    width: 100%;
    text-align: right;
    color: white;
    animation: fadeIn 0.5s;
    animation-name: text-fadein;
    @media (max-width: 900px) {
      text-align: center;
      margin-bottom: 20px;
    }
    @keyframes text-fadein {
      from {
        opacity: 0.7;
        margin-right: -20px;
      }
      to {
        opacity: 1;
        margin-left: 0;
      }
    }
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.green};
  max-width: 1000px;
  .axis-horizontal {
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    @media (max-width: 00px) {
      padding: 0;
    }
  }
  .thumbs-wrapper.axis-vertical {
    margin-bottom: 0px;
    overflow-x: scroll;
    &:-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 900px) {
      padding: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
      max-width: 540px;
      ul {
        padding: 0;
      }
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .thumbs,
  .thumbs.animated {
    padding: 0 !important;
    width: 100% !important;
    text-align: center;
  }

  .control-arrow.control-prev,
  .control-arrow.control-next {
    display: none;
  }
  .carousel,
  .carousel-root {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
  }

  .slide {
    border-radius: 5px;
    background-color: ${theme.colors.green};
  }
  .carousel-slider {
    overflow: visible;
  }

  .thumb {
    border: none;
    border-radius: 2px;
    padding: 0;
    background-color: ${theme.colors.background};
    cursor: pointer;
  }
  .thumb:hover {
    border: 2px solid #3e3e3e;
    box-shadow: none;
  }
  .thumb.selected {
    border: 2px solid ${theme.colors.orange};
    border-radius: 2px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  }
  h1 {
    color: white;
  }

  @media (max-width: 600px) {
    margin-bottom: 30px;
  }
`;
const CarouselWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: 900px) {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
`;

const teacherStories = [
  {
    quote:
      'When did you last look forward to take your students on a learning journey with you?',
    excerpt:
      "I am teacher of 15 years who, until recently, has delved no more deeply into tech in the classroom, and here I am encouraging colleagues to come on in and see Curipod in action and leading CPD sessions. To top it off, I'm spending my evenings playing on my iPad to see how many new lessons I can create, and what new features have been added that week. The time saved, and the improvement in my lesson materials, is tangible.",
    link: '/blog/A Curipod Case Study By Aileen Wallace-329667c610dc40cf88bfc7e2eb207e7a',
    linkText: '- Read the full case study with Aileen Wallace',
    imageSrc: Aileen.src,
  },
  {
    quote:
      'I have this young kid struggling, and she got so excited when the class pulled up her work!',
    excerpt:
      'I am a 30 year veteran of math education, and I found Curipod one night when I was looking for ways to increase student participation. I tried it the next day and I was hooked. The students were actually excited to use it! I LOVE that they each have their own whiteboard and that we can use their responses as great conversation starters. ',
    link: '',
    linkText: 'Gretchen Cuccio, Pearl R Miller Middle School',
    imageSrc: Gretchen.src,
  },
  {
    quote:
      'The learning activity I thought would take a few minutes, turned into a 45 minute discussion, where students were hitting all their learning targets',
    excerpt:
      'As an instructional technologist I can easily create lessons for my PDs and help teachers getting started. We get more creative with AI generated activities and the student engagement is substantially improved.',
    link: '',
    linkText: 'Stephanie Howell, Pickerington Local School District',
    imageSrc: Stephanie.src,
  },
];

const TeacherStory = () => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

  return (
    <TeacherStoryContainer>
      <TeacherStoryWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <div>
            <TeacherQuote>
              &ldquo;{teacherStories[currentStoryIndex].quote}&rdquo;
            </TeacherQuote>
          </div>
        </div>
        <TeacherStoryDiv>
          <p style={{ textAlign: 'left' }}>{teacherStories[currentStoryIndex].excerpt}</p>
          {teacherStories[currentStoryIndex].link !== '' && (
            <div style={{ marginBottom: 20 }}>
              <Link href={teacherStories[currentStoryIndex].link}>
                <a style={{ textDecoration: 'underline' }}>
                  {teacherStories[currentStoryIndex].linkText}
                </a>
              </Link>
            </div>
          )}
          {teacherStories[currentStoryIndex].link === '' && (
            <p style={{ fontWeight: 400, textAlign: 'left', marginBottom: 20 }}>
              {teacherStories[currentStoryIndex].linkText}
            </p>
          )}
          <Image
            src={teacherStories[currentStoryIndex].imageSrc}
            height="100px"
            width="100px"
          />
        </TeacherStoryDiv>
      </TeacherStoryWrapper>
      <Dots>
        <DotButton
          $isActive={currentStoryIndex === 0}
          onClick={() => setCurrentStoryIndex(0)}
        ></DotButton>
        <DotButton
          $isActive={currentStoryIndex === 1}
          onClick={() => setCurrentStoryIndex(1)}
        ></DotButton>
        <DotButton
          $isActive={currentStoryIndex === 2}
          onClick={() => setCurrentStoryIndex(2)}
        ></DotButton>
      </Dots>
    </TeacherStoryContainer>
  );
};

const TeacherStoryContainer = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    height: 700px;
  }
  @media (max-width: 900px) {
    height: 700px;
  }
  @media (max-width: 600px) {
    height: 800px;
    padding: 0px 10px;
  }
  @media (max-width: 450px) {
    height: 800px;
  }
`;
const TeacherStoryWrapper = styled.div`
  padding: 20px;
  display: flex;
  background-color: ${theme.colors.background};
  padding-top: 50px;
  padding-bottom: 20px;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: space-evenly;
  overflow: hidden;
  height: 100%;

  @media (max-width: 900px) {
    h1 {
      margin-top: 40px;
      margin-bottom: 0px;
    }
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
  }
  @media (max-width: 550px) {
    h1 {
      font-size: 20px;
    }

    p {
      font-size: 16px !important;
    }
  }
`;

const TeacherStoryDiv = styled.div`
  padding: 10px;
  padding-left: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 600px;
  width: 100%;

  p {
    font-size: 20px;
    font-weight: 300;
  }

  a {
    font-size: 20px;
    font-weight: 400;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    padding-left: 10px;
    h1,
    h3,
    p {
      margin-top: 30px;
      text-align: center;
    }
  }
`;

const TeacherQuote = styled.h1`
  padding: 10px 20px;
  font-style: italic;
  font-size: 30px;
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
`;

const DotButton = styled(Button)<{ $isActive: boolean }>`
  visibility: visible !important;
  margin: 0 5px;
  width: 10px !important;
  height: 10px;
  border-radius: 50%;
  padding: 0px;
  background-color: ${theme.colors.greyDark};
  background-color: ${({ $isActive }) =>
    $isActive ? theme.colors.green : theme.colors.grey};
`;

const ManyStudents = () => {
  const { t } = useTranslation('Host');
  return (
    <ManyStudentsContainer>
      <h1 style={{ width: '100%', textAlign: 'center' }}>
        More than 5 million {t('students')}
      </h1>

      <h2 style={{ width: '100%', textAlign: 'center' }}>
        {t('have shared their voices')}
      </h2>
    </ManyStudentsContainer>
  );
};
const ManyStudentsContainer = styled.div`
  padding: 20px;
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  h1 {
    font-size: 48px;
    margin: 0;
    color: ${theme.colors.black};
  }
  h2 {
    font-size: 24px;
    color: ${theme.colors.black};
  }
  @media (max-width: 900px) {
    h1 {
      font-size: 40px;
      margin: 0;
    }
    h2 {
      font-size: 20px;
    }
  }
`;

const SignupForLiteracyLessons = () => {
  const router = useRouter();
  const metrics = useMetrics();

  const handleSignupClicked = () => {
    metrics.logEvent('FrontPage.SignupClicked', {
      from: 'literacyLessons',
    });
    router.push({
      pathname: '/signup',
      query: { callback: window.location.href },
    });
  };
  return (
    <div style={{ backgroundColor: theme.colors.green, padding: '80px 0px' }}>
      <div style={{ width: '100%', maxWidth: '800px', margin: 'auto' }}>
        <h3
          style={{
            fontSize: 30,
            fontWeight: 'bold',
            color: 'white',
            width: '100%',
            textAlign: 'center',
            padding: '0px 20px',
          }}
        >
          Sign up to find more K-12 lessons for literacy, science, social studies, math,
          and more
        </h3>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
            maxWidth: 600,
            margin: 'auto',
            paddingTop: 40,
          }}
        >
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <span
              style={{
                width: '100%',
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Teachers
            </span>
            <Button
              styles={{ backgroundColor: theme.colors.orange, width: '170px' }}
              onClick={() => handleSignupClicked()}
            >
              Sign up
            </Button>
          </div>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <span
              style={{
                width: '100%',
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Administrators
            </span>
            <a href="/quotes" target="_curipod_quote">
              <Button
                type="ghost"
                styles={{
                  backgroundColor: 'transparent',
                  borderColor: 'white',
                  color: 'white',
                  width: '170px',
                }}
                onClick={() => {
                  metrics.logEvent('FrontPage.QuoteClicked', {
                    from: 'literacyLessons',
                  });
                }}
              >
                Get a school quote
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ThousandsOfClassrooms = () => {
  return (
    <ThousandsOfClassroomsContainer>
      <SectionTitle>Trusted by school and district partners worldwide</SectionTitle>
      <LogosGrid>
        <TopRow>
          <img src={SchoolLogo1.src} alt="... school logo" />
          <img src={SchoolLogo2.src} alt="... school logo" />
          <img src={SchoolLogo3.src} alt="... school logo" />
          <img src={SchoolLogo4.src} alt="... school logo" />
        </TopRow>
        <BottomRow>
          <img src={SchoolLogo5.src} alt="... school logo" />
          <img src={SchoolLogo6.src} alt="... school logo" />
          <img src={SchoolLogo7.src} alt="... school logo" />
        </BottomRow>
      </LogosGrid>
    </ThousandsOfClassroomsContainer>
  );
};

const ThousandsOfClassroomsContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.background};
  padding-top: 50px;
  padding-bottom: 40px;
  align-items: center;
  overflow: hidden;

  @media (max-width: 900px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

const LogosGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;
  align-items: center;
  gap: 20px;
  justify-items: center;
  padding-top: 20px;

  img {
    margin: 0 auto;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TopRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  img {
    max-height: 100px;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const BottomRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1rem;
  img {
    max-height: 100px;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    img:nth-child(3) {
      grid-column: 1 / 3;
    }
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    img:nth-child(3) {
      grid-column: 1 / 2;
    }
  }
`;

export default UnauthenticatedHome;
